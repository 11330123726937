<script>
import layoutProfile from "@/views/layouts/layoutProfile";
import simplebar from "simplebar-vue";
import {membershipMethods, membershipComputed, paymentCardListComputed, paymentCardMethods} from "@/state/helpers";

export default {
	name: "Membership",
	components: {
		simplebar,
		layoutProfile,
	},
	data() {
		return {
			fields: ['start_date', 'exp_date', 'card', 'amount', 'status', 'actions'],
		}
	},
	computed: {
		...membershipComputed,
		...paymentCardListComputed,
		filteredItems() {
			return this.membershipList.map((item) => {
				return {
					id: item.id,
					start_date:  item.previous_transaction_at,
					exp_date:  item.next_transaction_at,
					card: item.last_card_num,
					amount: `$${item.amount}`,
					status:  item.status
				}
			})
		}
	},
	methods: {
		...membershipMethods,
		...paymentCardMethods,
		updateMembership(id, status) {
			const item = {
				id: id,
				status: status
			}
			this.$store.dispatch('membership/updateMembership', item)
		},
	},
	mounted() {
		this.$store.dispatch('membership/getMembershipList');
		this.$store.dispatch("paymentCardList/getPaymentCardsList");
	}
}
</script>

<template>
	<layoutProfile>
		<div class="payment-method-outer">
			<div class="px-4 pt-4">
				<div class="d-flex justify-content-between align-items-center">
					<h4 class="mb-0">Membership</h4>
				</div>
			</div>
			<simplebar class="p-4 user-profile-desc">
				<div class="card">
					<div class="card-body p-4 filter-table">
						<b-table :items="filteredItems" :fields="fields" responsive="sm" class="text-center">
							<template v-slot:cell(card)="{ item }">
								<span>{{item.card === '' ? '-' : '*'+item.card}}</span>
							</template>
							<template v-slot:cell(status)="{ item }">
								<span
									:class="item.status === 'active' ? 'status-green' : 'status-yellow'"
								>{{item.status}}</span>
							</template>
							<template v-slot:cell(actions)="{ item }">
								<b-dropdown v-if="item.status !== 'cancelled' && paymentCardList.length && item.amount !== '$1'" toggle-class="nav-btn" variant="white" right>
									<template v-slot:button-content>
										<i class="ri-more-fill"></i>
									</template>
									<b-dropdown-item
										v-if="item.status !== 'active'"
										class="d-block user-profile-show"
										@click="updateMembership(item.id, 1)"
									>
										Activate
										<i class="ri-checkbox-circle-line float-right text-muted"></i>
									</b-dropdown-item>
									<b-dropdown-item
										v-if="item.status !== 'paused'"
										class="d-block user-profile-show"
										@click="updateMembership(item.id, 2)"
									>
										Pause
										<i class="ri-pause-circle-line float-right text-muted"></i>
									</b-dropdown-item>
<!--									<b-dropdown-item v-if="item.status !== 'cancelled'" @click="updateMembership(item.id, 3)">-->
<!--										Cancel-->
<!--										<i class="ri-close-circle-line float-right text-muted"></i>-->
<!--									</b-dropdown-item>-->
								</b-dropdown>
							</template>
						</b-table>
					</div>
				</div>
			</simplebar>
		</div>
	</layoutProfile>
</template>